import React from 'react'
import { Card, Button } from 'antd'
import { Container, Col, Image, Row } from 'react-bootstrap'
import { getChoiceLabel } from '../ftiHelper'
import classnames from 'classnames'
import styled from 'styled-components'
import { FtiFlipDirection, FtiExerciseSet } from '../ftiSlice'
import { APIMEDIA_HOST } from '../../../conf/hosts'
import FooterButtons from '../../common/components/FooterButtons'

export interface IFtiExerciseProps {
  currentSet: number
  level?: number
  kind?: FtiFlipDirection
  exerciseSet?: FtiExerciseSet
  answer?: number
  pickAnswer: (choice: number) => void
  next: () => void
}

const Question = styled(Card)`
  box-shadow: 0 0 7px rgba(0,0,0,0.5);
  border: solid 2px black;
  border-radius: 5px;
`

const Choice = styled(Button)`
  height: 100% !important;
  border: solid 2px black;

  &.selected {
    border: solid 4px #1890ff;
  }
`

const FtiExercise = ({ currentSet, level, kind, exerciseSet, answer, pickAnswer, next }: IFtiExerciseProps) => {

  if (level === undefined || kind === undefined || exerciseSet === undefined) { return null }

  const { question, answers } = exerciseSet

  const answerCols = answers.map((answerSrc, index) => {
    const buttonClasses = classnames('pt-3', { selected: answer === index })
    return (
      <Col xs={10} sm={8} md={4} key={`answer-${index}`} className="my-1">
        <Choice className={buttonClasses} block onClick={() => pickAnswer(index)}>
          <Image draggable={false} src={`${APIMEDIA_HOST}${answerSrc}`} fluid height="115px" />
          <h6>{getChoiceLabel(index)}</h6>
        </Choice>
      </Col>
    )
  })

  const verticalInfo = (
    <>
      <p>Below are a series of images presented in separate rows. Look at the images carefully. Your task is to try to flip the top row image <strong>vertically</strong> (upside down). Then, choose among the three possible matches in the bottom row (labeled “A, B, and C”).</p>
      <p>Hint:  Focus on a part of the image in the question. Then, compare each answer to see which image is correctly flipped vertically.</p>
    </>
  )

  const horizontalInfo = (
    <>
      <p>Below are a series of images presented in separate rows. Look at the images carefully. Your task is to try to flip the top row image <strong>horizontally</strong> (left to right, like turning a page in a book). Then, choose among the three possible matches in the bottom row (labeled “A, B, and C”).</p>
      <p>Hint:  Focus on a part of the image in the question. Then, compare each answer to see which image is correctly flipped horizontally.</p>
    </>
  )

  const info = kind === 'vertical' ? verticalInfo : horizontalInfo

  return (
    <>
      <Container>
        <h4>Level {level}</h4>
        {info}
        <Row className="mb-3 justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Question className="text-center">
              <h5 className="text-left">{`Set ${currentSet}`}</h5>
              <Image draggable={false} src={`${APIMEDIA_HOST}${question}`} fluid height="135px" />
            </Question>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {answerCols}
        </Row>
      </Container>
      <FooterButtons 
        buttons={[{ disabled: answer === undefined,label: "Next", action: next, buttonType: "primary" }]} 
      />
    </>
)
}

export default FtiExercise